import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/home'
import './App.css';

const App = () => {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          {/* Route for the riot.txt file */}
          <Route
            path="/riot.txt"
            element={
              <iframe
                title="Riot.txt"
                src="/.netlify/functions/riotTxt" // Point to the Netlify function
                style={{ border: 'none', width: '100%', height: '100vh' }}
              />
            }
          />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
