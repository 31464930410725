import React, { useState } from 'react';
import styled from 'styled-components';
import Selectcard from './selectcard';
import Playercard from './playercard';
import Result from './result';

const StyledCards = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    
    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        max-width: 100vw;
    }
`;

const StyledBorder = styled.div`
    border-radius: 12px;
    background-color: rgb(0, 18, 33);
    margin: 20px;
    width: 351px;
    height: 698px;
    flex: 0 0 auto;
    z-index: 2;
    
`;

const CardInner = styled.div`
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: transform 1s cubic-bezier(0.42, 0, 0.58, 1);
    transform: ${({ isFlipped }) => (isFlipped ? 'rotateY(180deg)' : 'rotateY(0deg)')};
    z-index: 1;
`;

const StyledResults = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: row; /* Arrange results vertically */
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px;
    line-height: 24px;
    
    @media (max-width: 768px) {
        margin-top: 10px;
    }
`;

const StyledSplashArt = styled.img`
    flex: 0 0 auto;
    width: 247.5px;
    height: 450px;
    margin-top: 12px;
    
    @media (max-width: 768px) {
        width: 100px;
        height: auto;
    }
`;

const StyledFlip = styled.div`
    perspective: 500px;
    transition: transform 1s cubic-bezier(0.42, 0, 0.58, 1);
    transform: ${({ isFlipped }) => (isFlipped ? 'rotateY(180deg)' : 'rotateY(0deg)')};
`;

function Card() {
    const [isComplete1, setIsComplete1] = useState(false);
    const [isComplete2, setIsComplete2] = useState(false);
    const [selectedChampionImage1, setSelectedChampionImage1] = useState('');
    const [selectedChampionImage2, setSelectedChampionImage2] = useState('');
    const [profileIconId1, setProfileIconId1] = useState(null); // Define profileIconId1
    const [profileIconId2, setProfileIconId2] = useState(null); // Define profileIconId2
    const [summonerRank1, setSummonerRank1] = useState(null); // Define summonerRank1
    const [summonerRank2, setSummonerRank2] = useState(null); // Define summonerRank2
    const [summonerMatchInfo1, setSummonerMatchInfo1] = useState(null); // Definate summoner 1 match history data
    const [summonerMatchInfo2, setSummonerMatchInfo2] = useState(null); // Definate summoner 2 match history data
    const [matchInfoSummary1, setMatchInfoSummary1] = useState("") // State to store the match history info after calculations
    const [matchInfoSummary2, setMatchInfoSummary2] = useState("") // State to store the match history info after calculations


    const toggleIsComplete1 = () => {
        setIsComplete1(!isComplete1);
    };

    const toggleIsComplete2 = () => {
        setIsComplete2(!isComplete2);
    };

    return (
        <StyledCards>
            <StyledFlip isFlipped={isComplete1}>
                <StyledBorder>
                    <CardInner isFlipped={isComplete1}>
                        {isComplete1 ? (
                            <Playercard
                                toggleIsComplete={toggleIsComplete1}
                                profileIconId={profileIconId1}
                                summonerRank={summonerRank1}
                                summonerMatchInfo={summonerMatchInfo1}
                                matchInfoSummary={matchInfoSummary1}
                                setMatchInfoSummary={setMatchInfoSummary1}
                                //selectedChampionImage={selectedChampionImage1}
                            />
                        ) : (
                            <Selectcard
                                toggleIsComplete={toggleIsComplete1}
                                setProfileIconId={setProfileIconId1}
                                setSelectedChampionImage={setSelectedChampionImage1}
                                setSummonerRank={setSummonerRank1}
                                setSummonerMatchInfo={setSummonerMatchInfo1}
                            />
                        )}
                    </CardInner>
                </StyledBorder>
            </StyledFlip>
            <StyledResults>
                {selectedChampionImage1 ? (
                    <StyledSplashArt
                        src={selectedChampionImage1}
                    />
                ) : (
                    <></>
                )}
                <Result
                    summonerRank1={summonerRank1}
                    matchInfoSummary1={matchInfoSummary1}
                    summonerRank2={summonerRank2}
                    matchInfoSummary2={matchInfoSummary2}
                />
                {selectedChampionImage2 ? (
                    <StyledSplashArt
                        src={selectedChampionImage2}
                    />
                ) : (
                    <></>
                )}
            </StyledResults>
            <StyledFlip isFlipped={isComplete2}>
                <StyledBorder>
                    <CardInner isFlipped={isComplete2}>
                        {isComplete2 ? (
                            <Playercard
                                toggleIsComplete={toggleIsComplete2}
                                profileIconId={profileIconId2}
                                summonerRank={summonerRank2}
                                summonerMatchInfo={summonerMatchInfo2}
                                matchInfoSummary={matchInfoSummary2}
                                setMatchInfoSummary={setMatchInfoSummary2}
                            />
                        ) : (
                            <Selectcard
                                toggleIsComplete={toggleIsComplete2}
                                setProfileIconId={setProfileIconId2}
                                setSelectedChampionImage={setSelectedChampionImage2}
                                setSummonerRank={setSummonerRank2}
                                setSummonerMatchInfo={setSummonerMatchInfo2}
                            />
                        )}
                    </CardInner>
                </StyledBorder>
            </StyledFlip>
        </StyledCards>
    );
}

export default Card;