// summonerCache.js
const summonerCache = {}; // Cache to store user data
const cacheExpiration = 5 * 60 * 1000; // Cache expiration time (5 minutes in milliseconds)

export const getCachedSummonerData = (userName) => {
    const cachedData = summonerCache[userName];
    if (cachedData) {
        // Check if the cached data is still valid
        const elapsedTime = Date.now() - cachedData.timestamp;
        if (elapsedTime <= cacheExpiration) {
            return cachedData.data; // Return the cached data
        } else {
            // Cache has expired, remove it
            delete summonerCache[userName];
        }
    }
    return null; // No valid cache found
};

export const cacheSummonerData = (userName, data) => {
    // Cache the summoner data
    summonerCache[userName] = {
        data,
        timestamp: Date.now(),
    };
};
