import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import championData from '../champion.json';
import { loadImage, getImage } from './imageCache';
import { getCachedSummonerData, cacheSummonerData } from './summonerCache';

const spinAnimation = keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
`;

const LoadingSpinner = styled.div`
    border: 4px solid rgb(179, 149, 86);
    border-top: 4px solid rgb(1, 5, 120);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin: 0 auto;
    animation: ${spinAnimation} 1s linear infinite;
    margin-top: 50%;
`;

const StyledChampList = styled.div`
    margin: 5px 10px 0px 10px;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
`;

const StyledIcons = styled.img`
    width: 31.1px;
    height: auto;
    margin: 1px;
    
`;

const StyledInput = styled.input`
    padding: 7px 12px 7px 12px;
    margin: 8px 10px 0px 10px;
    border: 0;
    border-radius: 12px;
    outline: 0;
    font-size: 16px;
    
`;

const StyledButton = styled.button`
    padding: 10px;
    margin: 5px 10px 8px 10px;
    background-color: rgb(1, 32, 53);
    color: rgb(179, 149, 86);
    font-weight: bold;
    border: none;
    border-radius: 12px;
    font-size: 16px;
    cursor: pointer;
    display: ${({ championsLoaded }) => (championsLoaded ? 'block' : 'none')};
    position: relative;
    overflow: hidden;
    width: auto;

    &:after {
        content: "";
        animation: ${({ isLoadingSearch }) =>
        isLoadingSearch ? 'blink-animation 1s infinite' : 'none'};
        position: absolute;
    }

    @keyframes blink-animation {
        0%, 100% {
            content: "";
        }
        25% {
            content: ". ";
        }
        50% {
            content: "..  ";
        }
        75% {
            content: "... ";
        }
    }
`;

const StyledDiv = styled.div`
    display: flex;
    flex-direction: column;
    backface-visibility: hidden;
    transform: rotateY(0deg);
`;


function Selectcard({
    toggleIsComplete,
    setProfileIconId,
    setSelectedChampionImage,
    setSummonerRank,
    setSummonerMatchInfo,
}) {
    const [userName, setUserName] = useState('');
    const [searchChamp, setSearchChamp] = useState('');
    const champNames = Object.keys(championData.data);
    const [champImagesLoaded, setChampImagesLoaded] = useState(false);
    const [selectChamp, setSelectChamp] = useState(null);
    const [allChampionsLoaded, setAllChampionsLoaded] = useState(false);
    const [isLoadingSearch, setIsLoadingSearch] = useState(false); // State for loading search
    
    useEffect(() => {
        const loadChampionImages = async () => {
            const imageLoadPromises = champNames.map(async (name) => {
                const imageUrl = `http://ddragon.leagueoflegends.com/cdn/13.24.1/img/champion/${name}.png?v=1`;
                await loadImage(imageUrl);
            });

            // Wait for all image load promises to resolve
            await Promise.all(imageLoadPromises);

            // All images are loaded, set champImagesLoaded to true and allChampionsLoaded to true
            setChampImagesLoaded(true);
            setAllChampionsLoaded(true);
        };

        loadChampionImages();
    }, [champNames]);

    const handleUserInput = (e) => {
        setUserName(e.target.value);
    };

    const handleChampInput = (e) => {
        setSearchChamp(e.target.value);
    };

    const handleSearch = () => {
        if (!selectChamp) {
            console.error('Please select a champion.');
            return; // Do not proceed with the API call
        }
        setIsLoadingSearch(true);
        // Check if the username and champion are already cached
        const cacheKey = `${userName}-${selectChamp}`;
        const cachedData = getCachedSummonerData(cacheKey);

        if (cachedData) {
            // Use cached data
            setProfileIconId(cachedData.profileIconId);
            setSummonerRank(cachedData.summonerRank);
            setSummonerMatchInfo(cachedData.matchInfo); // Use cached matchInfo
            toggleIsComplete();
        } else {
            // Fetch fresh data from the server
            setIsLoadingSearch(true);
            let fetchedData;

            fetch(`/api/fetch-summoner?name=${userName}`)
                .then((response) => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! Status: ${response.status}`);
                    }
                    return response.json();
                })
                .then((data) => {
                    // Store fetched data in the 'fetchedData' variable
                    fetchedData = data;

                    // Getting the match ID's
                    return fetch(`/api/fetch-match-history?puuid=${fetchedData.puuid}`);
                })
                .then((res) => {
                    if (!res.ok) {
                        throw new Error(`HTTP error! Status: ${res.status}`);
                    }
                    return res.json();
                })
                .then((res) => {
                    return fetch(`/api/fetch-match-info?matchIds=${res}&selectedChampion=${selectChamp}&summonerPuuid=${fetchedData.puuid}`);
                })
                .then((matchInfoResponse) => {
                    if (!matchInfoResponse.ok) {
                        throw new Error(`HTTP error! Status: ${matchInfoResponse.status}`);
                    }
                    return matchInfoResponse.json();
                })
                .then((matchInfoData) => {
                    // Update fetchedData.matchInfo with matchInfoData
                    fetchedData.matchInfo = matchInfoData;

                    // matchInfoData is an array of detailed match information objects
                    // You can work with this data as needed
                    setSummonerMatchInfo(matchInfoData);

                    // Set profileIconId using the fetched data
                    setProfileIconId(fetchedData.profileIconId);

                    // Fetch rank data
                    return fetch(`/api/fetch-summoner-rank?summonerId=${fetchedData.id}`);
                })
                .then((rankResponse) => rankResponse.json())
                .then((rankData) => {
                    // Set summonerRank using the fetched data
                    setSummonerRank(rankData);
                    toggleIsComplete();

                    // Cache the fetched data including matchInfoData
                    cacheSummonerData(cacheKey, {
                        profileIconId: fetchedData.profileIconId,
                        summonerRank: rankData,
                        matchInfo: fetchedData.matchInfo,
                    });
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    setIsLoadingSearch(false);
                })
        }
        setUserName('');
    };

    const filteredChamps = champNames.filter((name) =>
        name.toLowerCase().includes(searchChamp.toLowerCase())
    );

    const handleChampSelect = (name) => {
        if (selectChamp === name) {
            setSelectChamp(null);
        } else {
            setSelectChamp(name);
            const selectedChampionImageUrl = `http://ddragon.leagueoflegends.com/cdn/img/champion/loading/${name}_0.jpg`;
            setSelectedChampionImage(selectedChampionImageUrl);
        }
    };

    return (
        <StyledDiv>
            <StyledInput
                type="text"
                value={userName}
                onChange={handleUserInput}
                placeholder="Enter Summoner Name"
            />
            <StyledInput
                type="text"
                value={searchChamp}
                onChange={handleChampInput}
                placeholder="Search Champion"
            />
            {champImagesLoaded ? (
                <StyledChampList>
                    {filteredChamps.map((name) => (
                        <React.Fragment key={name}>
                            {getImage(
                                `http://ddragon.leagueoflegends.com/cdn/13.24.1/img/champion/${name}.png?v=1`
                            ) ? (
                                <StyledIcons
                                    src={getImage(
                                        `http://ddragon.leagueoflegends.com/cdn/13.24.1/img/champion/${name}.png?v=1`
                                    ).src}
                                    alt={name}
                                    onClick={() => handleChampSelect(name)}
                                    style={{
                                        border:
                                            selectChamp === name
                                                ? '2px solid rgb(167, 133, 63)'
                                                : 'none',
                                        margin: selectChamp === name ? '-1px' : '1px',
                                    }}
                                />
                            ) : null}
                        </React.Fragment>
                    ))}
                </StyledChampList>
            ) : (
                <LoadingSpinner />
            )}
            <StyledButton
                onClick={handleSearch}
                championsLoaded={allChampionsLoaded}
                isLoadingSearch={isLoadingSearch}
                disabled={isLoadingSearch}
            >
                {isLoadingSearch ? 'Loading' : 'Submit'}
            </StyledButton>
        </StyledDiv>
    );
}

export default Selectcard;
