import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

const HeaderContent = styled.div`

    background-size: cover;
    background-color: #00082e; 
    color: rgb(179, 149, 86);
    text-align: center;
    font-size: 2rem; 
    width: 100vw;
    margin: 0;
    padding: 1.1rem 0;
    padding-bottom: 1.2rem;
    position: relative;
    overflow:hidden;
    @media (max-width: 768px) {
        width: 100vw;
        height: auto;
        padding-bottom: 2.5rem;
    }
`;


const LColumn = styled.div`
    font-family: 'Press Start 2P';
    font-size: 6rem; 
    line-height: 0.70; 
    margin-top: 1.5rem;
    margin-right: -2.5rem; 
    display: inline-block;
    transform: scaleY(1.2); 
    vertical-align: middle; 
    
    @media (max-width: 768px) {
        position: absolute;
        margin-top: 1.0rem;
        margin-left: -1.0rem;
        font-size: 2rem;
        transform: scaleY(1.2);
    }
`;
const PCTText = styled.div`
    font-family: 'Press Start 2P', cursive;
    font-size: 2rem; 
    display: inline-block;
    vertical-align: middle; 
    
    @media (max-width: 768px) {
        font-size: 0.8rem;
    }
`;

const LeftText = styled.span`
    margin-right: 27%;
    font-size: 2rem; 
    
    @media (max-width: 768px) {
        margin-right: 36%;
        font-size: 0.7rem;
    }
`;

//<StyledIcon icon={faBars} />
function Header() {
    return (
        <HeaderContent>
            
            <LColumn>L</LColumn>
            <PCTText>
                <LeftText>eague of Legends</LeftText>
                <br />Player Comparison Tool
            </PCTText>
        </HeaderContent>
    );
}

export default Header;
