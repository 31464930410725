import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import Header from './header';
import Card from './card';
import Footer from './footer';
const GlobalStyles = createGlobalStyle`
    body {
        background-color: #020513; 
        font-family: 'Press Start 2P';
        margin: 0;
        padding: 0;
    }
`;

const AppContainer = styled.div`
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media (max-width: 768px) {
        overflow: hidden;
    }
`;

const MainContent = styled.div`
    background-color: #13294b; 
    min-width: 90%;
    border-radius: 12px;
    padding: 20px;
    margin-top: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    @media (max-width: 768px) {
        overflow: hidden;
        max-width: 100vw;
        border-radius: 0px;
    }
`;

function Home() {
    return (
        <>
            <GlobalStyles />
            <Header />
            <AppContainer>
                
                <MainContent>
                    <Card />
                </MainContent>
                
            </AppContainer>
            <Footer />
        </>
    );
}

export default Home;