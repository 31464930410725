import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';

const StyledDiv = styled.div`
    display: flex;
    justify-content: flex-start;
`

const StyledBackButton = styled.button`
    background-color: rgb(1, 32, 53);
    border: none;
    border-radius: 12px;
    padding: 10px;
    margin: 44.5px 0px 0px 44.5px;
    width: 40px;
    height: 40px;
    align-self: flex-start;
    cursor: pointer;
`

const StyledProfileIconParent = styled.div`
    flex: 1 0 auto;
    text-align: center;
    margin-right: 84.5px;
`

const StyledProfileIcon = styled.img`
    width: 100px; /* Adjust the width as needed */
    height: auto; /* Maintain aspect ratio */
    border-radius: 50%;
    border-style: solid;
    border-width: 5px;
    border-color: rgb(167, 133, 63);
    margin-top: 12px;
    
`;


const StyledIcon = styled(FontAwesomeIcon)`
  color: rgb(179, 149, 86);
`;

const StyledPlaceholder = styled.p`
    text-align: center;
    color: white;
`
const StyledRotate = styled.div`
    transform: rotateY(180deg);
    backface-visibility: hidden;
`

const StyledFlip = styled.div`
    transform: scaleX(-1);
`
const StyledText = styled.p`
    font-size: 15px;
    transform: scaleX(0.75);
    line-height: 1.1;
    @media (max-width: 768px) {
        font-size: 15px;
        
    }
`
const StyledHr = styled.hr`
    width: 75%;
`


function Playercard({ 
    summonerMatchInfo,
    toggleIsComplete,
    profileIconId,
    summonerRank,
    matchInfoSummary,
    setMatchInfoSummary
}) {
    const [userName, setUserName] = useState(""); // State to store user input
    const [summonerData, setSummonerData] = useState({}); // State to store the input summoner name data
    const [profileIcon, setProfileIcon] = useState("");

    const [profileIconUrl, setProfileIconUrl] = useState(""); // State to store the profile icon URL
    //const [matchInfoSummary, setMatchInfoSummary] = useState("") // State to store the match history info after calculations

    const handleInputChange = (e) => {
        setUserName(e.target.value);
    };

    const handleEnterKey = (e) => {
        if (e.key === 'Enter') {
            handleSearch();
        }
    }

    useEffect(() => {
        let totalKills = 0;
        let totalDeaths = 0;
        let totalAssists = 0;
        let totalVision = 0;
        let totalFbParticipation = 0;
        let totalCS = 0;
        let totalDamage = 0;
        let totalWin = 0;
        let totalTimePlayed = 0;

        let info = {
            champion: 'undefined',
            killAvg: 'undefined',
            deathAvg: 'undefined',
            assistAvg: 'undefined',
            KDAavg: 'undefined',
            visionAvg: 'undefined',
            fbParticipationAvg: 'undefined',
            csAvg: 'undefined',
            dmgAvg: 'undefined',
            winRate: 'undefined',
            totalGames: 'undefined',
            gameLengthAvg: 'undefined'
        };

        if (summonerMatchInfo && summonerMatchInfo.length > 0) {
            // Gets total number of values over however many games of specified champion
            for (let i = 0; i < summonerMatchInfo.length; i++) {
                totalKills += summonerMatchInfo[i].participant.kills;
                totalDeaths += summonerMatchInfo[i].participant.deaths;
                totalAssists += summonerMatchInfo[i].participant.assists;
                totalVision += summonerMatchInfo[i].participant.visionScore;
                if (summonerMatchInfo[i].participant.fbAssist || summonerMatchInfo[i].participant.fbBloodKill) {
                    totalFbParticipation += 1;
                }
                totalCS += (summonerMatchInfo[i].participant.neutralMinionsKilled + summonerMatchInfo[i].participant.totalMinionsKilled);
                totalDamage += summonerMatchInfo[i].participant.totalDmg;
                if (summonerMatchInfo[i].participant.win) {
                    totalWin += 1;
                }
                totalTimePlayed += summonerMatchInfo[i].gameInfo.gameDuration;
            }

            // Avg values of data over however many games of specified champion
            info = {
                champion: summonerMatchInfo[0].participant.championName,
                killAvg: (totalKills / summonerMatchInfo.length).toFixed(1),
                deathAvg: (totalDeaths / summonerMatchInfo.length).toFixed(1),
                assistAvg: (totalAssists / summonerMatchInfo.length).toFixed(1),
                KDAavg: ((totalKills + totalAssists) / totalDeaths).toFixed(2),
                visionAvg: (totalVision / summonerMatchInfo.length).toFixed(1),
                fbParticipationAvg: (totalFbParticipation / summonerMatchInfo.length * 100).toFixed(2),
                csAvg: (totalCS / summonerMatchInfo.length).toFixed(1),
                csAvgPerMin: ((totalCS / summonerMatchInfo.length) / (totalTimePlayed / summonerMatchInfo.length / 60)).toFixed(1),
                dmgAvg: (totalDamage / summonerMatchInfo.length).toFixed(2),
                winRate: (totalWin / summonerMatchInfo.length * 100).toFixed(2),
                totalGames: summonerMatchInfo.length,
                gameLengthAvgMin: (totalTimePlayed / summonerMatchInfo.length / 60).toFixed(0),
                gameLengthAvgSec: (totalTimePlayed / summonerMatchInfo.length % 60).toFixed(0)
            };
        }

        setMatchInfoSummary(info);

    }, [summonerMatchInfo])

    useEffect(() => {
        // Load the profile icon image and store it in the cache
        const profileIconImageUrl = `http://ddragon.leagueoflegends.com/cdn/13.18.1/img/profileicon/${profileIconId}.png`;

        // Set the profile icon URL to be displayed
        setProfileIconUrl(profileIconImageUrl);

        const profileIconImage = new Image();
        profileIconImage.src = profileIconImageUrl;
        profileIconImage.onload = () => {
            setProfileIcon(profileIconImage.src); // Store profile icon URL in the cache
        };
    }, [profileIconId]);

    const handleSearch = () => {
        fetch(`/api/fetch-summoner?name=${userName}`)
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                setSummonerData(data);
                toggleIsComplete();
            })
            .catch(error => {
                console.error(error);
            });

        setUserName('');
    };
    const rankedSolo5x5 = summonerRank.find(rank => rank.queueType === "RANKED_SOLO_5x5");
    return (
        <StyledRotate>
            <StyledFlip>
                <StyledDiv>
                    <StyledBackButton onClick={toggleIsComplete}><StyledIcon icon={faArrowLeft} /></StyledBackButton>
                    <StyledProfileIconParent>
                        <StyledProfileIcon src={profileIconUrl} alt="Profile Icon" />
                    </StyledProfileIconParent>
                </StyledDiv>
                <StyledText>
                {rankedSolo5x5 ? (
                    <>
                        <StyledPlaceholder>{rankedSolo5x5.summonerName}</StyledPlaceholder>
                        <StyledPlaceholder>{`Ranked Solo: ${rankedSolo5x5.tier} ${rankedSolo5x5.rank}`}</StyledPlaceholder>
                        <StyledPlaceholder>{`Win Rate: ${((rankedSolo5x5.wins / (rankedSolo5x5.losses + rankedSolo5x5.wins)) * 100).toFixed(2)}%`}</StyledPlaceholder>
                        <StyledPlaceholder>{`${rankedSolo5x5.wins}W ${rankedSolo5x5.losses}L`}</StyledPlaceholder>
                        <StyledHr />
                        <StyledPlaceholder>{`Avg Stats on ${matchInfoSummary.totalGames} most recent games on ${matchInfoSummary.champion}`}</StyledPlaceholder>
                        <StyledPlaceholder>{`Win Rate ${matchInfoSummary.winRate}%`}</StyledPlaceholder>
                        <StyledPlaceholder>{`KDA ${matchInfoSummary.KDAavg} (${matchInfoSummary.killAvg} / ${matchInfoSummary.deathAvg} / ${matchInfoSummary.assistAvg})`}</StyledPlaceholder>
                        <StyledPlaceholder>{`CS ${matchInfoSummary.csAvg} (${matchInfoSummary.csAvgPerMin})`}</StyledPlaceholder>
                        <StyledPlaceholder>{`First Blood Participation ${matchInfoSummary.fbParticipationAvg} %`}</StyledPlaceholder>
                        <StyledPlaceholder>{`Vision Score ${matchInfoSummary.visionAvg}`}</StyledPlaceholder>
                        <StyledPlaceholder>{`Damage ${matchInfoSummary.dmgAvg}`}</StyledPlaceholder>
                        <StyledPlaceholder>{`Game Length ${matchInfoSummary.gameLengthAvgMin}m ${matchInfoSummary.gameLengthAvgSec}s`}</StyledPlaceholder>
                    </>
                ) : (
                    <StyledPlaceholder>No Ranked Solo 5x5 Data for this user.</StyledPlaceholder>
                )}
                </StyledText>
                {/* <StyledTextSearch>
                    <div>
                        <StyledInput
                            type="text"
                            value={userName}
                            onChange={handleInputChange}
                            onKeyDown={handleEnterKey}
                            placeholder="Enter Summoner Name"
                        />
                        <StyledButton onClick={handleSearch}><StyledIcon icon={faArrowRight} /></StyledButton>
                    </div>
                </StyledTextSearch> */}
            </StyledFlip>
        </StyledRotate>
    )
}

export default Playercard;