const imageCache = {};

export const loadImage = (url) => {
    return new Promise((resolve, reject) => {
        if (imageCache[url]) {
            resolve(imageCache[url]);
        } else {
            const image = new Image();
            image.src = url;
            image.onload = () => {
                imageCache[url] = image;
                resolve(image);
            };
            image.onerror = (error) => {
                reject(error);
            };
        }
    });
};

export const getImage = (url) => {
    return imageCache[url] || null;
};
