import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const StyledResultContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledResult = styled.div`
  margin-top: 20px;
  color: ${props => props.higher ? 'lightgreen' : props.lower ? '#E3242B' : 'white'};
  display: ${props => props.show ? 'block' : 'none'};
`;

const LineBreak = styled.br`
`; 

function Result({
    summonerRank1,
    matchInfoSummary1,
    summonerRank2,
    matchInfoSummary2
}) {
    const [resultValues, setResultValues] = useState({
        summonerOneWinPercent: 0,
        summonerTwoWinPercent: 0
    });
    useEffect(() => {
        let result = {
            summonerOneWinPercent: 0,
            summonerTwoWinPercent: 0
        };

        let rankWinPercent1 = 0; // 50% of total win share
        let csWinPercent1 = 0; // 12.5% of total win share
        let kdaWinPercent1 = 0; // 12.5% of total win share
        let visionWinPercent1 = 0; // 12.5% of total win share
        let wrWinPercent1 = 0; // 12.5% of total win share

        let rankWinPercent2 = 0; // 50% of total win share
        let csWinPercent2 = 0; // 12.5% of total win share
        let kdaWinPercent2 = 0; // 12.5% of total win share
        let visionWinPercent2 = 0; // 12.5% of total win share
        let wrWinPercent2 = 0; // 12.5% of total win share

        // tier and rank used to determine how many point values each rank is
        const tier = {
            iron: 0,
            bronze: 4,
            silver: 8,
            gold: 12,
            platinum: 16,
            emerald: 20,
            diamond: 24,
            master: 300,
            grandmaster: 400,
            challenger: 500
        }

        const rank = {
            IV: 1,
            III: 2,
            II: 3,
            I: 4
        }

        // When both summoners are submitted with the respective champions calculate % win share of rank
        if (summonerRank1 && summonerRank2) {
            const rankedSolo5x5s1 = summonerRank1.find(rank => rank.queueType === "RANKED_SOLO_5x5");
            const rankedSolo5x5s2 = summonerRank2.find(rank => rank.queueType === "RANKED_SOLO_5x5");

            // Calculate % win share based on tier and rank

            let tierRankValue1;
            let tierRankValue2;

            let d3Checker = false;
            let d2Checker = false;

            // Accomodate the skill difference from Diamond III to Diamond II for summoner 1
            if (!(rankedSolo5x5s1.tier.toLowerCase() === 'diamond') || (!(rankedSolo5x5s1.rank === 'I') && !(rankedSolo5x5s1.rank === 'II'))) {
                if (rankedSolo5x5s1.tier.toLowerCase() === 'diamond' && rankedSolo5x5s1.rank === 'III') {
                    d3Checker = true;
                }

                tierRankValue1 = tier[rankedSolo5x5s1.tier.toLowerCase()] + rank[rankedSolo5x5s1.rank];
            } else {
                if (rankedSolo5x5s1.rank === 'II') {
                    d2Checker = true;
                }
                
                tierRankValue1 = 200 + rank[rankedSolo5x5s1.rank];
            }

            // Accomodate the skill difference from Diamond III to Diamond II for summoner 2
            if (!(rankedSolo5x5s2.tier.toLowerCase() === 'diamond') || (!(rankedSolo5x5s2.rank === 'I') && !(rankedSolo5x5s2.rank === 'II'))) {
                if (rankedSolo5x5s1.tier.toLowerCase() === 'diamond' && rankedSolo5x5s1.rank === 'III') {
                    d3Checker = true;
                }

                tierRankValue2 = tier[rankedSolo5x5s2.tier.toLowerCase()] + rank[rankedSolo5x5s2.rank];
            } else {
                if (rankedSolo5x5s2.rank === 'II') {
                    d2Checker = true;
                }

                tierRankValue2 = 200 + rank[rankedSolo5x5s2.rank];
            }

            const tierRankValueTotal = tierRankValue1 + tierRankValue2;

            // Calculate % win share based on cs/min
            const cs1 = Number(matchInfoSummary1.csAvgPerMin);
            const cs2 = Number(matchInfoSummary2.csAvgPerMin);
            const csTotal = cs1 + cs2;

            // Calculate % win share based on kda
            const kda1 = Number(matchInfoSummary1.KDAavg);
            const kda2 = Number(matchInfoSummary2.KDAavg);
            const kdaTotal = kda1 + kda2;

            // Calculate % win share based on vision score
            const vs1 = Number(matchInfoSummary1.visionAvg);
            const vs2 = Number(matchInfoSummary1.visionAvg);
            const vsTotal = vs1 + vs2;

            // Calculate % win share based on WR
            const wr1 = Number(matchInfoSummary1.winRate) + Number((Number(rankedSolo5x5s1.wins) / (Number(rankedSolo5x5s1.wins) + Number(rankedSolo5x5s1.losses)) * 100).toFixed(2));
            const wr2 = Number(matchInfoSummary2.winRate) + Number((Number(rankedSolo5x5s2.wins) / (Number(rankedSolo5x5s2.wins) + Number(rankedSolo5x5s2.losses)) * 100).toFixed(2));
            const wrTotal = wr1 + wr2;

            
            if (Math.abs(tierRankValue2 - tierRankValue1) > 1 || (d3Checker && d2Checker)) {
                // If they are not within the same tier and 1 rank a apart or D2 and D3

                rankWinPercent1 = ((tierRankValue1 / tierRankValueTotal) * 100).toFixed(2) * .9;
                rankWinPercent2 = ((tierRankValue2 / tierRankValueTotal) * 100).toFixed(2) * .9;

                csWinPercent1 = ((cs1 / csTotal) * 100).toFixed(2) * .025;
                csWinPercent2 = ((cs2 / csTotal) * 100).toFixed(2) * .025;

                kdaWinPercent1 = ((kda1 / kdaTotal) * 100).toFixed(2) * .025;
                kdaWinPercent2 = ((kda2 / kdaTotal) * 100).toFixed(2) * .025;

                visionWinPercent1 = ((vs1 / vsTotal) * 100).toFixed(2) * .025;
                visionWinPercent2 = ((vs2 / vsTotal) * 100).toFixed(2) * .025;

                wrWinPercent1 = ((wr1 / wrTotal) * 100).toFixed(2) * .025;
                wrWinPercent2 = ((wr2 / wrTotal) * 100).toFixed(2) * .025;

            } else {
                // Same teir and within 1 rank apart

                rankWinPercent1 = 0;
                rankWinPercent2 = 0;

                csWinPercent1 = ((cs1 / csTotal) * 100).toFixed(2) * .25;
                csWinPercent2 = ((cs2 / csTotal) * 100).toFixed(2) * .25;

                kdaWinPercent1 = ((kda1 / kdaTotal) * 100).toFixed(2) * .25;
                kdaWinPercent2 = ((kda2 / kdaTotal) * 100).toFixed(2) * .25;

                visionWinPercent1 = ((vs1 / vsTotal) * 100).toFixed(2) * .25;
                visionWinPercent2 = ((vs2 / vsTotal) * 100).toFixed(2) * .25;

                wrWinPercent1 = ((wr1 / wrTotal) * 100).toFixed(2) * .25;
                wrWinPercent2 = ((wr2 / wrTotal) * 100).toFixed(2) * .25;
            }


            result.summonerOneWinPercent = (rankWinPercent1 + csWinPercent1 + kdaWinPercent1 + visionWinPercent1 + wrWinPercent1).toFixed(2) + '%';
            result.summonerTwoWinPercent = (rankWinPercent2 + csWinPercent2 + kdaWinPercent2 + visionWinPercent2 + wrWinPercent2).toFixed(2) + '%';

            setResultValues(result);
        }
    }, [summonerRank1, matchInfoSummary1, summonerRank2, matchInfoSummary2])

    const { summonerOneWinPercent = 0, summonerTwoWinPercent = 0 } = resultValues;

    return (
        <StyledResultContainer>
                    <StyledResult
                        show={summonerTwoWinPercent !== 0}
                    >
                        Win Chance
                    </StyledResult>
                    <StyledResult
                        higher={summonerOneWinPercent > summonerTwoWinPercent}
                        lower={summonerOneWinPercent < summonerTwoWinPercent}
                        show={summonerOneWinPercent !== 0}
                    >
                        P1: {summonerOneWinPercent}
                    </StyledResult>
                    <StyledResult
                        higher={summonerTwoWinPercent > summonerOneWinPercent}
                        lower={summonerTwoWinPercent < summonerOneWinPercent}
                        show={summonerTwoWinPercent !== 0}
                    >
                        P2: {summonerTwoWinPercent}
                    </StyledResult>
        </StyledResultContainer>
    )
}

export default Result;